
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as accounting_45exportWKU3rO0WsSMeta } from "/opt/build/repo/pages/accounting-export.vue?macro=true";
import { default as indexgqto3bYk6iMeta } from "/opt/build/repo/pages/accounting/index.vue?macro=true";
import { default as pending_45sync3wB9Xv5P8YMeta } from "/opt/build/repo/pages/accounting/pending-sync.vue?macro=true";
import { default as accounting0BctGaYK86Meta } from "/opt/build/repo/pages/accounting.vue?macro=true";
import { default as indexrCDFILjKL3Meta } from "/opt/build/repo/pages/admin/dashboard/index.vue?macro=true";
import { default as indexWvbi1x4mA9Meta } from "/opt/build/repo/pages/admin/emails/index.vue?macro=true";
import { default as index7qcPXZQ3fgMeta } from "/opt/build/repo/pages/admin/index.vue?macro=true";
import { default as adminAOa9i81ClJMeta } from "/opt/build/repo/pages/admin.vue?macro=true";
import { default as indexyYoRS52qDvMeta } from "/opt/build/repo/pages/companies/[id]/accounting/index.vue?macro=true";
import { default as setupPLALJ2aTP4Meta } from "/opt/build/repo/pages/companies/[id]/accounting/setup.vue?macro=true";
import { default as contactserwBiDGZhEMeta } from "/opt/build/repo/pages/companies/[id]/contacts.vue?macro=true";
import { default as cost_45codesyluEwrQjBiMeta } from "/opt/build/repo/pages/companies/[id]/cost-codes.vue?macro=true";
import { default as indexIDogGMEzQnMeta } from "/opt/build/repo/pages/companies/[id]/index.vue?macro=true";
import { default as purchase_45orderFM1AjJ8LN4Meta } from "/opt/build/repo/pages/companies/[id]/purchase-order.vue?macro=true";
import { default as report_45configKK0pW3J3MxMeta } from "/opt/build/repo/pages/companies/[id]/report-config.vue?macro=true";
import { default as restrictedda5cJ0APkXMeta } from "/opt/build/repo/pages/companies/[id]/restricted.vue?macro=true";
import { default as rolesjF8L9My80vMeta } from "/opt/build/repo/pages/companies/[id]/roles.vue?macro=true";
import { default as accountingZZnTwTwPWDMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/accounting.vue?macro=true";
import { default as contactslCLUCGnH1YMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/contacts.vue?macro=true";
import { default as indexPayNn7cWCLMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/index.vue?macro=true";
import { default as mapHgLjrpu7VsMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/map.vue?macro=true";
import { default as _91companyVendorId_93UGEpqNrJ5BMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId].vue?macro=true";
import { default as categoriesgoVnSGFn22Meta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/categories.vue?macro=true";
import { default as contacts1bOaigw3RQMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/contacts.vue?macro=true";
import { default as in_45storecSn9KilMW7Meta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/in-store.vue?macro=true";
import { default as indexAtJAzR4KYcMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/index.vue?macro=true";
import { default as newu5OpAtg5nEMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add/new.vue?macro=true";
import { default as addoNe5OipBVjMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/add.vue?macro=true";
import { default as indexKK4l1x9oTyMeta } from "/opt/build/repo/pages/companies/[id]/suppliers/index.vue?macro=true";
import { default as suppliers0oqo87bY3KMeta } from "/opt/build/repo/pages/companies/[id]/suppliers.vue?macro=true";
import { default as _91id_931AV2ody235Meta } from "/opt/build/repo/pages/companies/[id].vue?macro=true";
import { default as componentsay62b1HMlmMeta } from "/opt/build/repo/pages/components.vue?macro=true";
import { default as allWCC4tZrpOaMeta } from "/opt/build/repo/pages/dashboard/all.vue?macro=true";
import { default as indexViWjQzLqkmMeta } from "/opt/build/repo/pages/dashboard/index.vue?macro=true";
import { default as my_45ordersbyc63iLtlYMeta } from "/opt/build/repo/pages/dashboard/my-orders.vue?macro=true";
import { default as dashboardB0S6bZ6pqpMeta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as indexNA5kvJylAnMeta } from "/opt/build/repo/pages/error-test/index.vue?macro=true";
import { default as editSB2p36Pe1RMeta } from "/opt/build/repo/pages/expenses/[id]/edit.vue?macro=true";
import { default as index4diJnNPCqrMeta } from "/opt/build/repo/pages/expenses/[id]/index.vue?macro=true";
import { default as _91id_93qe6ZCJiSH4Meta } from "/opt/build/repo/pages/expenses/[id].vue?macro=true";
import { default as export3EfnpVwBOpMeta } from "/opt/build/repo/pages/expenses/export.vue?macro=true";
import { default as indexvuiyxuxBN8Meta } from "/opt/build/repo/pages/expenses/index.vue?macro=true";
import { default as newHklXGQ4zxSMeta } from "/opt/build/repo/pages/expenses/new.vue?macro=true";
import { default as expensesk7AcdHSqk8Meta } from "/opt/build/repo/pages/expenses.vue?macro=true";
import { default as resetymq0xjlkgBMeta } from "/opt/build/repo/pages/forgot-password/[uuid]/reset.vue?macro=true";
import { default as check_45emailxlf7QdUQyEMeta } from "/opt/build/repo/pages/forgot-password/check-email.vue?macro=true";
import { default as index3EcQ0vRq6EMeta } from "/opt/build/repo/pages/forgot-password/index.vue?macro=true";
import { default as forgot_45password6Wrw3WyRqiMeta } from "/opt/build/repo/pages/forgot-password.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as inboxvS9qgSNRsdMeta } from "/opt/build/repo/pages/invoices/inbox.vue?macro=true";
import { default as exportP5XghAugT1Meta } from "/opt/build/repo/pages/invoices/index/export.vue?macro=true";
import { default as indexGGCfJMGl0hMeta } from "/opt/build/repo/pages/invoices/index.vue?macro=true";
import { default as invoicesL4yOPl6MAJMeta } from "/opt/build/repo/pages/invoices.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as bom_45pricingBYd8xl7CdgMeta } from "/opt/build/repo/pages/orders/[id]/bom-pricing.vue?macro=true";
import { default as bom_45items5Fjyt8Q2c4Meta } from "/opt/build/repo/pages/orders/[id]/change-order/bom-items.vue?macro=true";
import { default as indexRaxMsbTFjQMeta } from "/opt/build/repo/pages/orders/[id]/change-order/index.vue?macro=true";
import { default as change_45orderTiIY2DrNDoMeta } from "/opt/build/repo/pages/orders/[id]/change-order.vue?macro=true";
import { default as bom_45itemsd0Znlw9w8gMeta } from "/opt/build/repo/pages/orders/[id]/complete-draft/bom-items.vue?macro=true";
import { default as indexEiM8KcJnbfMeta } from "/opt/build/repo/pages/orders/[id]/complete-draft/index.vue?macro=true";
import { default as complete_45draftNAWkLkNGUNMeta } from "/opt/build/repo/pages/orders/[id]/complete-draft.vue?macro=true";
import { default as edithm1AQSUbP7Meta } from "/opt/build/repo/pages/orders/[id]/edit.vue?macro=true";
import { default as in_45storeqBWrl8GmioMeta } from "/opt/build/repo/pages/orders/[id]/in-store.vue?macro=true";
import { default as index6q3YeMG7apMeta } from "/opt/build/repo/pages/orders/[id]/index.vue?macro=true";
import { default as invite_45suppliersYXk1U0P8r6Meta } from "/opt/build/repo/pages/orders/[id]/invite-suppliers.vue?macro=true";
import { default as _91invoice_93tJaavm4HEOMeta } from "/opt/build/repo/pages/orders/[id]/invoices/[invoice].vue?macro=true";
import { default as _91quote_93w3oimy6hKoMeta } from "/opt/build/repo/pages/orders/[id]/quotes/[quote].vue?macro=true";
import { default as fullgFh7kCjHDNMeta } from "/opt/build/repo/pages/orders/[id]/receive/full.vue?macro=true";
import { default as indexBEBOFJ9D01Meta } from "/opt/build/repo/pages/orders/[id]/receive/index.vue?macro=true";
import { default as partialCWoruO6bRYMeta } from "/opt/build/repo/pages/orders/[id]/receive/partial.vue?macro=true";
import { default as submiteF2lp1t64dMeta } from "/opt/build/repo/pages/orders/[id]/receive/submit.vue?macro=true";
import { default as receiveG3qQAZ0Yu0Meta } from "/opt/build/repo/pages/orders/[id]/receive.vue?macro=true";
import { default as _91id_93TTgZ6DPPQGMeta } from "/opt/build/repo/pages/orders/[id].vue?macro=true";
import { default as indexi2fh0drxOOMeta } from "/opt/build/repo/pages/orders/index.vue?macro=true";
import { default as deliveryq3qPFTmjn9Meta } from "/opt/build/repo/pages/orders/new/delivery.vue?macro=true";
import { default as indexWKnbTQUEO4Meta } from "/opt/build/repo/pages/orders/new/index.vue?macro=true";
import { default as invite_45supplierscnRfPbFZ3MMeta } from "/opt/build/repo/pages/orders/new/invite-suppliers.vue?macro=true";
import { default as review1FIAJvCl7TMeta } from "/opt/build/repo/pages/orders/new/review.vue?macro=true";
import { default as newzoomwQnfWxMeta } from "/opt/build/repo/pages/orders/new.vue?macro=true";
import { default as indexLJfq1P35t7Meta } from "/opt/build/repo/pages/profile/index.vue?macro=true";
import { default as notificationsg94mAVLjTyMeta } from "/opt/build/repo/pages/profile/notifications.vue?macro=true";
import { default as passwordsWDNYqO8wAMeta } from "/opt/build/repo/pages/profile/password.vue?macro=true";
import { default as profileNJNbyWXx9rMeta } from "/opt/build/repo/pages/profile.vue?macro=true";
import { default as _91bomId_9322oWpiF8NAMeta } from "/opt/build/repo/pages/projects/[id]/boms/[bomId].vue?macro=true";
import { default as indexoOF6RfBS7CMeta } from "/opt/build/repo/pages/projects/[id]/index.vue?macro=true";
import { default as _91id_939LOMfExrirMeta } from "/opt/build/repo/pages/projects/[id].vue?macro=true";
import { default as indexxtMqaql9AYMeta } from "/opt/build/repo/pages/projects/index.vue?macro=true";
import { default as newUCNIqFdKkpMeta } from "/opt/build/repo/pages/projects/new.vue?macro=true";
import { default as bom_45items2NQPvDcutuMeta } from "/opt/build/repo/pages/purchase-orders/new/bom-items.vue?macro=true";
import { default as indexCpNcZnZzkoMeta } from "/opt/build/repo/pages/purchase-orders/new/index.vue?macro=true";
import { default as new6iYug0E8qUMeta } from "/opt/build/repo/pages/purchase-orders/new.vue?macro=true";
import { default as indexNWoueekKfCMeta } from "/opt/build/repo/pages/requisitions/[id]/index.vue?macro=true";
import { default as indexgQ9QiiZopwMeta } from "/opt/build/repo/pages/requisitions/index.vue?macro=true";
import { default as requisitions6HE4l0tOneMeta } from "/opt/build/repo/pages/requisitions.vue?macro=true";
import { default as setup_45modeL9qDT6k3uMMeta } from "/opt/build/repo/pages/setup-mode.vue?macro=true";
import { default as categories2J4CB32WCXMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/categories.vue?macro=true";
import { default as companiesUNZwHIwsw5Meta } from "/opt/build/repo/pages/suppliers/[id]/edit/companies.vue?macro=true";
import { default as _91contactId_93VSy8D4PwfOMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/contacts/[contactId].vue?macro=true";
import { default as indexpFVKuKWopIMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/contacts/index.vue?macro=true";
import { default as newJdiKwt02DDMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/contacts/new.vue?macro=true";
import { default as indexEhNdi2euANMeta } from "/opt/build/repo/pages/suppliers/[id]/edit/index.vue?macro=true";
import { default as editslUuXPTL09Meta } from "/opt/build/repo/pages/suppliers/[id]/edit.vue?macro=true";
import { default as mergeFHPzZIgnd0Meta } from "/opt/build/repo/pages/suppliers/[id]/merge.vue?macro=true";
import { default as _91id_93pKgEiXZtZSMeta } from "/opt/build/repo/pages/suppliers/[id].vue?macro=true";
import { default as indexnp3RSlEeRbMeta } from "/opt/build/repo/pages/suppliers/index.vue?macro=true";
import { default as categories7sUc7C17xUMeta } from "/opt/build/repo/pages/suppliers/new/categories.vue?macro=true";
import { default as indexKlaMwNYGv4Meta } from "/opt/build/repo/pages/suppliers/new/contacts/index.vue?macro=true";
import { default as newJx8rLPkmb6Meta } from "/opt/build/repo/pages/suppliers/new/contacts/new.vue?macro=true";
import { default as indexCUk2cJRpLpMeta } from "/opt/build/repo/pages/suppliers/new/index.vue?macro=true";
import { default as newQZx9KifUAdMeta } from "/opt/build/repo/pages/suppliers/new.vue?macro=true";
import { default as suppliersLPOkiFovuWMeta } from "/opt/build/repo/pages/suppliers.vue?macro=true";
import { default as editjZAd5BA6vAMeta } from "/opt/build/repo/pages/users/[id]/edit.vue?macro=true";
import { default as indexO2KkEMigJMMeta } from "/opt/build/repo/pages/users/accept-invite/[uuid]/index.vue?macro=true";
import { default as indexIJSpSfJfmnMeta } from "/opt/build/repo/pages/users/index.vue?macro=true";
import { default as newttbkpK96RcMeta } from "/opt/build/repo/pages/users/new.vue?macro=true";
import { default as indexhgXGwd0mWlMeta } from "/opt/build/repo/pages/invoices/new/index.vue?macro=true";
import { default as match_45itemsaRUOQdElF5Meta } from "/opt/build/repo/pages/invoices/new/match-items.vue?macro=true";
import { default as newHgUZvosTWaMeta } from "/opt/build/repo/pages/invoices/new.vue?macro=true";
import { default as editOMgk7W8gLlMeta } from "/opt/build/repo/pages/invoices/[id]/edit.vue?macro=true";
import { default as match_45itemsLzGsZ1YJTIMeta } from "/opt/build/repo/pages/invoices/[id]/match-items.vue?macro=true";
import { default as _91id_93Wb3O729292Meta } from "/opt/build/repo/pages/invoices/[id].vue?macro=true";
import { default as approval9BtcEVbtCSMeta } from "/opt/build/repo/pages/requisitions/new/approval.vue?macro=true";
import { default as deliveryD3xLWQTLcOMeta } from "/opt/build/repo/pages/requisitions/new/delivery.vue?macro=true";
import { default as indexQdLVPGWFPEMeta } from "/opt/build/repo/pages/requisitions/new/index.vue?macro=true";
import { default as reviewveMOQLYlpzMeta } from "/opt/build/repo/pages/requisitions/new/review.vue?macro=true";
import { default as newtiIup9ycgqMeta } from "/opt/build/repo/pages/requisitions/new.vue?macro=true";
import { default as editwEvXq143bFMeta } from "/opt/build/repo/pages/requisitions/[id]/edit.vue?macro=true";
export default [
  {
    name: "accounting-export",
    path: "/accounting-export",
    meta: accounting_45exportWKU3rO0WsSMeta || {},
    component: () => import("/opt/build/repo/pages/accounting-export.vue")
  },
  {
    name: accounting0BctGaYK86Meta?.name,
    path: "/accounting",
    meta: accounting0BctGaYK86Meta || {},
    component: () => import("/opt/build/repo/pages/accounting.vue"),
    children: [
  {
    name: "accounting",
    path: "",
    component: () => import("/opt/build/repo/pages/accounting/index.vue")
  },
  {
    name: "accounting-pending-sync",
    path: "pending-sync",
    component: () => import("/opt/build/repo/pages/accounting/pending-sync.vue")
  }
]
  },
  {
    name: adminAOa9i81ClJMeta?.name,
    path: "/admin",
    meta: adminAOa9i81ClJMeta || {},
    component: () => import("/opt/build/repo/pages/admin.vue"),
    children: [
  {
    name: "admin-dashboard",
    path: "dashboard",
    component: () => import("/opt/build/repo/pages/admin/dashboard/index.vue")
  },
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/opt/build/repo/pages/admin/emails/index.vue")
  },
  {
    name: "admin",
    path: "",
    meta: index7qcPXZQ3fgMeta || {},
    component: () => import("/opt/build/repo/pages/admin/index.vue")
  }
]
  },
  {
    name: _91id_931AV2ody235Meta?.name,
    path: "/companies/:id()",
    meta: _91id_931AV2ody235Meta || {},
    component: () => import("/opt/build/repo/pages/companies/[id].vue"),
    children: [
  {
    name: "companies-id-accounting",
    path: "accounting",
    meta: indexyYoRS52qDvMeta || {},
    component: () => import("/opt/build/repo/pages/companies/[id]/accounting/index.vue")
  },
  {
    name: "companies-id-accounting-setup",
    path: "accounting/setup",
    meta: setupPLALJ2aTP4Meta || {},
    component: () => import("/opt/build/repo/pages/companies/[id]/accounting/setup.vue")
  },
  {
    name: "companies-id-contacts",
    path: "contacts",
    component: () => import("/opt/build/repo/pages/companies/[id]/contacts.vue")
  },
  {
    name: "companies-id-cost-codes",
    path: "cost-codes",
    component: () => import("/opt/build/repo/pages/companies/[id]/cost-codes.vue")
  },
  {
    name: "companies-id",
    path: "",
    component: () => import("/opt/build/repo/pages/companies/[id]/index.vue")
  },
  {
    name: "companies-id-purchase-order",
    path: "purchase-order",
    component: () => import("/opt/build/repo/pages/companies/[id]/purchase-order.vue")
  },
  {
    name: "companies-id-report-config",
    path: "report-config",
    component: () => import("/opt/build/repo/pages/companies/[id]/report-config.vue")
  },
  {
    name: "companies-id-restricted",
    path: "restricted",
    meta: restrictedda5cJ0APkXMeta || {},
    component: () => import("/opt/build/repo/pages/companies/[id]/restricted.vue")
  },
  {
    name: "companies-id-roles",
    path: "roles",
    component: () => import("/opt/build/repo/pages/companies/[id]/roles.vue")
  },
  {
    name: suppliers0oqo87bY3KMeta?.name,
    path: "suppliers",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers.vue"),
    children: [
  {
    name: _91companyVendorId_93UGEpqNrJ5BMeta?.name,
    path: ":companyVendorId()",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId].vue"),
    children: [
  {
    name: "companies-id-suppliers-companyVendorId-accounting",
    path: "accounting",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/accounting.vue")
  },
  {
    name: "companies-id-suppliers-companyVendorId-contacts",
    path: "contacts",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/contacts.vue")
  },
  {
    name: "companies-id-suppliers-companyVendorId",
    path: "",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/index.vue")
  },
  {
    name: "companies-id-suppliers-companyVendorId-map",
    path: "map",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/[companyVendorId]/map.vue")
  }
]
  },
  {
    name: addoNe5OipBVjMeta?.name,
    path: "add",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add.vue"),
    children: [
  {
    name: "companies-id-suppliers-add-categories",
    path: "categories",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/categories.vue")
  },
  {
    name: "companies-id-suppliers-add-contacts",
    path: "contacts",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/contacts.vue")
  },
  {
    name: "companies-id-suppliers-add-in-store",
    path: "in-store",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/in-store.vue")
  },
  {
    name: "companies-id-suppliers-add",
    path: "",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/index.vue")
  },
  {
    name: "companies-id-suppliers-add-new",
    path: "new",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/add/new.vue")
  }
]
  },
  {
    name: "companies-id-suppliers",
    path: "",
    component: () => import("/opt/build/repo/pages/companies/[id]/suppliers/index.vue")
  }
]
  }
]
  },
  {
    name: "components",
    path: "/components",
    meta: componentsay62b1HMlmMeta || {},
    component: () => import("/opt/build/repo/pages/components.vue")
  },
  {
    name: dashboardB0S6bZ6pqpMeta?.name,
    path: "/dashboard",
    meta: dashboardB0S6bZ6pqpMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-all",
    path: "all",
    component: () => import("/opt/build/repo/pages/dashboard/all.vue")
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-my-orders",
    path: "my-orders",
    component: () => import("/opt/build/repo/pages/dashboard/my-orders.vue")
  }
]
  },
  {
    name: "error-test",
    path: "/error-test",
    meta: indexNA5kvJylAnMeta || {},
    component: () => import("/opt/build/repo/pages/error-test/index.vue")
  },
  {
    name: expensesk7AcdHSqk8Meta?.name,
    path: "/expenses",
    meta: expensesk7AcdHSqk8Meta || {},
    component: () => import("/opt/build/repo/pages/expenses.vue"),
    children: [
  {
    name: _91id_93qe6ZCJiSH4Meta?.name,
    path: ":id()",
    meta: _91id_93qe6ZCJiSH4Meta || {},
    component: () => import("/opt/build/repo/pages/expenses/[id].vue"),
    children: [
  {
    name: "expenses-id-edit",
    path: "edit",
    meta: editSB2p36Pe1RMeta || {},
    component: () => import("/opt/build/repo/pages/expenses/[id]/edit.vue")
  },
  {
    name: "expenses-id",
    path: "",
    component: () => import("/opt/build/repo/pages/expenses/[id]/index.vue")
  }
]
  },
  {
    name: "expenses-export",
    path: "export",
    component: () => import("/opt/build/repo/pages/expenses/export.vue")
  },
  {
    name: "expenses",
    path: "",
    component: () => import("/opt/build/repo/pages/expenses/index.vue")
  },
  {
    name: "expenses-new",
    path: "new",
    component: () => import("/opt/build/repo/pages/expenses/new.vue")
  }
]
  },
  {
    name: forgot_45password6Wrw3WyRqiMeta?.name,
    path: "/forgot-password",
    meta: forgot_45password6Wrw3WyRqiMeta || {},
    component: () => import("/opt/build/repo/pages/forgot-password.vue"),
    children: [
  {
    name: "forgot-password-uuid-reset",
    path: ":uuid()/reset",
    component: () => import("/opt/build/repo/pages/forgot-password/[uuid]/reset.vue")
  },
  {
    name: "forgot-password-check-email",
    path: "check-email",
    component: () => import("/opt/build/repo/pages/forgot-password/check-email.vue")
  },
  {
    name: "forgot-password",
    path: "",
    component: () => import("/opt/build/repo/pages/forgot-password/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: invoicesL4yOPl6MAJMeta?.name,
    path: "/invoices",
    meta: invoicesL4yOPl6MAJMeta || {},
    component: () => import("/opt/build/repo/pages/invoices.vue"),
    children: [
  {
    name: "invoices-inbox",
    path: "inbox",
    component: () => import("/opt/build/repo/pages/invoices/inbox.vue")
  },
  {
    name: "invoices",
    path: "",
    meta: indexGGCfJMGl0hMeta || {},
    component: () => import("/opt/build/repo/pages/invoices/index.vue"),
    children: [
  {
    name: "invoices-index-export",
    path: "export",
    component: () => import("/opt/build/repo/pages/invoices/index/export.vue")
  }
]
  }
]
  },
  {
    name: "login",
    path: "/login",
    meta: loginBXD9sq3T3sMeta || {},
    component: () => import("/opt/build/repo/pages/login.vue")
  },
  {
    name: _91id_93TTgZ6DPPQGMeta?.name,
    path: "/orders/:id()",
    meta: _91id_93TTgZ6DPPQGMeta || {},
    component: () => import("/opt/build/repo/pages/orders/[id].vue"),
    children: [
  {
    name: "orders-id-bom-pricing",
    path: "bom-pricing",
    component: () => import("/opt/build/repo/pages/orders/[id]/bom-pricing.vue")
  },
  {
    name: change_45orderTiIY2DrNDoMeta?.name,
    path: "change-order",
    component: () => import("/opt/build/repo/pages/orders/[id]/change-order.vue"),
    children: [
  {
    name: "orders-id-change-order-bom-items",
    path: "bom-items",
    meta: bom_45items5Fjyt8Q2c4Meta || {},
    component: () => import("/opt/build/repo/pages/orders/[id]/change-order/bom-items.vue")
  },
  {
    name: "orders-id-change-order",
    path: "",
    component: () => import("/opt/build/repo/pages/orders/[id]/change-order/index.vue")
  }
]
  },
  {
    name: complete_45draftNAWkLkNGUNMeta?.name,
    path: "complete-draft",
    component: () => import("/opt/build/repo/pages/orders/[id]/complete-draft.vue"),
    children: [
  {
    name: "orders-id-complete-draft-bom-items",
    path: "bom-items",
    meta: bom_45itemsd0Znlw9w8gMeta || {},
    component: () => import("/opt/build/repo/pages/orders/[id]/complete-draft/bom-items.vue")
  },
  {
    name: "orders-id-complete-draft",
    path: "",
    component: () => import("/opt/build/repo/pages/orders/[id]/complete-draft/index.vue")
  }
]
  },
  {
    name: "orders-id-edit",
    path: "edit",
    component: () => import("/opt/build/repo/pages/orders/[id]/edit.vue")
  },
  {
    name: "orders-id-in-store",
    path: "in-store",
    component: () => import("/opt/build/repo/pages/orders/[id]/in-store.vue")
  },
  {
    name: "orders-id",
    path: "",
    component: () => import("/opt/build/repo/pages/orders/[id]/index.vue")
  },
  {
    name: "orders-id-invite-suppliers",
    path: "invite-suppliers",
    component: () => import("/opt/build/repo/pages/orders/[id]/invite-suppliers.vue")
  },
  {
    name: "orders-id-invoices-invoice",
    path: "invoices/:invoice()",
    component: () => import("/opt/build/repo/pages/orders/[id]/invoices/[invoice].vue")
  },
  {
    name: "orders-id-quotes-quote",
    path: "quotes/:quote()",
    component: () => import("/opt/build/repo/pages/orders/[id]/quotes/[quote].vue")
  },
  {
    name: receiveG3qQAZ0Yu0Meta?.name,
    path: "receive",
    component: () => import("/opt/build/repo/pages/orders/[id]/receive.vue"),
    children: [
  {
    name: "orders-id-receive-full",
    path: "full",
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/full.vue")
  },
  {
    name: "orders-id-receive",
    path: "",
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/index.vue")
  },
  {
    name: "orders-id-receive-partial",
    path: "partial",
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/partial.vue")
  },
  {
    name: "orders-id-receive-submit",
    path: "submit",
    component: () => import("/opt/build/repo/pages/orders/[id]/receive/submit.vue")
  }
]
  }
]
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexi2fh0drxOOMeta || {},
    component: () => import("/opt/build/repo/pages/orders/index.vue")
  },
  {
    name: newzoomwQnfWxMeta?.name,
    path: "/orders/new",
    meta: newzoomwQnfWxMeta || {},
    component: () => import("/opt/build/repo/pages/orders/new.vue"),
    children: [
  {
    name: "orders-new-delivery",
    path: "delivery",
    component: () => import("/opt/build/repo/pages/orders/new/delivery.vue")
  },
  {
    name: "orders-new",
    path: "",
    component: () => import("/opt/build/repo/pages/orders/new/index.vue")
  },
  {
    name: "orders-new-invite-suppliers",
    path: "invite-suppliers",
    component: () => import("/opt/build/repo/pages/orders/new/invite-suppliers.vue")
  },
  {
    name: "orders-new-review",
    path: "review",
    component: () => import("/opt/build/repo/pages/orders/new/review.vue")
  }
]
  },
  {
    name: profileNJNbyWXx9rMeta?.name,
    path: "/profile",
    meta: profileNJNbyWXx9rMeta || {},
    component: () => import("/opt/build/repo/pages/profile.vue"),
    children: [
  {
    name: "profile",
    path: "",
    component: () => import("/opt/build/repo/pages/profile/index.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/opt/build/repo/pages/profile/notifications.vue")
  },
  {
    name: "profile-password",
    path: "password",
    component: () => import("/opt/build/repo/pages/profile/password.vue")
  }
]
  },
  {
    name: _91id_939LOMfExrirMeta?.name,
    path: "/projects/:id()",
    component: () => import("/opt/build/repo/pages/projects/[id].vue"),
    children: [
  {
    name: "projects-id-boms-bomId",
    path: "boms/:bomId()",
    meta: _91bomId_9322oWpiF8NAMeta || {},
    component: () => import("/opt/build/repo/pages/projects/[id]/boms/[bomId].vue")
  },
  {
    name: "projects-id",
    path: "",
    meta: indexoOF6RfBS7CMeta || {},
    component: () => import("/opt/build/repo/pages/projects/[id]/index.vue")
  }
]
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexxtMqaql9AYMeta || {},
    component: () => import("/opt/build/repo/pages/projects/index.vue")
  },
  {
    name: "projects-new",
    path: "/projects/new",
    meta: newUCNIqFdKkpMeta || {},
    component: () => import("/opt/build/repo/pages/projects/new.vue")
  },
  {
    name: new6iYug0E8qUMeta?.name,
    path: "/purchase-orders/new",
    meta: new6iYug0E8qUMeta || {},
    component: () => import("/opt/build/repo/pages/purchase-orders/new.vue"),
    children: [
  {
    name: "purchase-orders-new-bom-items",
    path: "bom-items",
    meta: bom_45items2NQPvDcutuMeta || {},
    component: () => import("/opt/build/repo/pages/purchase-orders/new/bom-items.vue")
  },
  {
    name: "purchase-orders-new",
    path: "",
    component: () => import("/opt/build/repo/pages/purchase-orders/new/index.vue")
  }
]
  },
  {
    name: requisitions6HE4l0tOneMeta?.name,
    path: "/requisitions",
    meta: requisitions6HE4l0tOneMeta || {},
    component: () => import("/opt/build/repo/pages/requisitions.vue"),
    children: [
  {
    name: "requisitions-id",
    path: ":id()",
    meta: indexNWoueekKfCMeta || {},
    component: () => import("/opt/build/repo/pages/requisitions/[id]/index.vue")
  },
  {
    name: "requisitions",
    path: "",
    component: () => import("/opt/build/repo/pages/requisitions/index.vue")
  }
]
  },
  {
    name: "setup-mode",
    path: "/setup-mode",
    component: () => import("/opt/build/repo/pages/setup-mode.vue")
  },
  {
    name: suppliersLPOkiFovuWMeta?.name,
    path: "/suppliers",
    meta: suppliersLPOkiFovuWMeta || {},
    component: () => import("/opt/build/repo/pages/suppliers.vue"),
    children: [
  {
    name: "suppliers-id",
    path: ":id()",
    component: () => import("/opt/build/repo/pages/suppliers/[id].vue"),
    children: [
  {
    name: editslUuXPTL09Meta?.name,
    path: "edit",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit.vue"),
    children: [
  {
    name: "suppliers-id-edit-categories",
    path: "categories",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/categories.vue")
  },
  {
    name: "suppliers-id-edit-companies",
    path: "companies",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/companies.vue")
  },
  {
    name: "suppliers-id-edit-contacts-contactId",
    path: "contacts/:contactId()",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/contacts/[contactId].vue")
  },
  {
    name: "suppliers-id-edit-contacts",
    path: "contacts",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/contacts/index.vue")
  },
  {
    name: "suppliers-id-edit-contacts-new",
    path: "contacts/new",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/contacts/new.vue")
  },
  {
    name: "suppliers-id-edit",
    path: "",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/edit/index.vue")
  }
]
  },
  {
    name: "suppliers-id-merge",
    path: "merge",
    component: () => import("/opt/build/repo/pages/suppliers/[id]/merge.vue")
  }
]
  },
  {
    name: "suppliers",
    path: "",
    component: () => import("/opt/build/repo/pages/suppliers/index.vue")
  },
  {
    name: newQZx9KifUAdMeta?.name,
    path: "new",
    component: () => import("/opt/build/repo/pages/suppliers/new.vue"),
    children: [
  {
    name: "suppliers-new-categories",
    path: "categories",
    component: () => import("/opt/build/repo/pages/suppliers/new/categories.vue")
  },
  {
    name: "suppliers-new-contacts",
    path: "contacts",
    component: () => import("/opt/build/repo/pages/suppliers/new/contacts/index.vue")
  },
  {
    name: "suppliers-new-contacts-new",
    path: "contacts/new",
    component: () => import("/opt/build/repo/pages/suppliers/new/contacts/new.vue")
  },
  {
    name: "suppliers-new",
    path: "",
    component: () => import("/opt/build/repo/pages/suppliers/new/index.vue")
  }
]
  }
]
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    meta: editjZAd5BA6vAMeta || {},
    component: () => import("/opt/build/repo/pages/users/[id]/edit.vue")
  },
  {
    name: "users-accept-invite-uuid",
    path: "/users/accept-invite/:uuid()",
    meta: indexO2KkEMigJMMeta || {},
    component: () => import("/opt/build/repo/pages/users/accept-invite/[uuid]/index.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexIJSpSfJfmnMeta || {},
    component: () => import("/opt/build/repo/pages/users/index.vue")
  },
  {
    name: "users-new",
    path: "/users/new",
    meta: newttbkpK96RcMeta || {},
    component: () => import("/opt/build/repo/pages/users/new.vue")
  },
  {
    name: newHgUZvosTWaMeta?.name,
    path: "/invoices/new",
    meta: newHgUZvosTWaMeta || {},
    component: () => import("/opt/build/repo/pages/invoices/new.vue"),
    children: [
  {
    name: "invoices-new",
    path: "",
    component: () => import("/opt/build/repo/pages/invoices/new/index.vue")
  },
  {
    name: "invoices-new-match-items",
    path: "match-items",
    component: () => import("/opt/build/repo/pages/invoices/new/match-items.vue")
  }
]
  },
  {
    name: "invoices-id",
    path: "/invoices/:id()",
    meta: _91id_93Wb3O729292Meta || {},
    component: () => import("/opt/build/repo/pages/invoices/[id].vue"),
    children: [
  {
    name: "invoices-id-edit",
    path: "edit",
    component: () => import("/opt/build/repo/pages/invoices/[id]/edit.vue")
  },
  {
    name: "invoices-id-match-items",
    path: "match-items",
    component: () => import("/opt/build/repo/pages/invoices/[id]/match-items.vue")
  }
]
  },
  {
    name: newtiIup9ycgqMeta?.name,
    path: "/requisitions/new",
    meta: newtiIup9ycgqMeta || {},
    component: () => import("/opt/build/repo/pages/requisitions/new.vue"),
    children: [
  {
    name: "requisitions-new-approval",
    path: "approval",
    component: () => import("/opt/build/repo/pages/requisitions/new/approval.vue")
  },
  {
    name: "requisitions-new-delivery",
    path: "delivery",
    component: () => import("/opt/build/repo/pages/requisitions/new/delivery.vue")
  },
  {
    name: "requisitions-new",
    path: "",
    component: () => import("/opt/build/repo/pages/requisitions/new/index.vue")
  },
  {
    name: "requisitions-new-review",
    path: "review",
    component: () => import("/opt/build/repo/pages/requisitions/new/review.vue")
  }
]
  },
  {
    name: "requisitions-id-edit",
    path: "/requisitions/:id()/edit",
    meta: editwEvXq143bFMeta || {},
    component: () => import("/opt/build/repo/pages/requisitions/[id]/edit.vue")
  }
]