import { v4 as uuidv4 } from 'uuid'
import { QUANTITY_DECIMAL_PLACES, SKU_NAME_LENGTH } from '~/constants'
import { decimal } from '~/models/filters'

interface Item {
  id: string
  cost_code?: string
  description: string
  has_rental_duration: boolean
  quantity: number
  rental_duration: number | null
  rental_duration_unit: string | null
  total_price: number
  unit: string
  unit_price: number | null
}

export function parseFromSummary(results: any, key: string): string | undefined {
  for (const doc of results.ExpenseDocuments) {
    for (const field of doc.SummaryFields) {
      if (field.Type?.Text === key && field.ValueDetection?.Text) {
        return field.ValueDetection.Text
      }
    }
  }
  return undefined
}

const defaultItem = (costCode: string, unit: string): Item => ({
  id: uuidv4(),
  cost_code: costCode,
  description: '',
  has_rental_duration: false,
  quantity: 1,
  rental_duration: null,
  rental_duration_unit: null,
  total_price: 0,
  unit,
  unit_price: null,
})

const extractNumber = (text: string, fallback = 0): string => {
  const cleanText = text.replace(/[^0-9.\-(]+/g, '').replace('(', '-')

  return cleanText.match(/-?\d+\.?\d*/)?.[0] ?? `${fallback}`
}

function removeLastDash(str: string): string {
  return str.replace(/(?: -|\n- -|\n-|-)+$/, '')
}

export default function parseItemAnalysis(
  results: any,
  defaultUnit: string,
  scanItemsSku: boolean,
  defaultCostCode?: string,
): any[] {
  const items: any[] = []

  results.ExpenseDocuments?.forEach((doc: any) => {
    doc.LineItemGroups?.forEach((group: any) => {
      group.LineItems?.forEach((item: any) => {
        let quantitySet = false
        const newItem = defaultItem(defaultCostCode ?? '', defaultUnit)
        let productCode = null

        item.LineItemExpenseFields?.forEach((field: any) => {
          if (!field.ValueDetection?.Text || !field.Type.Text) {
            return
          }

          if (field.Type.Text === 'PRODUCT_CODE') {
            productCode = removeLastDash(field.ValueDetection.Text)
          }

          if (field.Type.Text === 'ITEM') {
            newItem.description = removeLastDash(field.ValueDetection.Text.slice(0, SKU_NAME_LENGTH))
          }
          else if (field.Type.Text === 'QUANTITY' && !quantitySet) {
            const quantity = extractNumber(field.ValueDetection.Text, 1)
            const floatQuantity = Number.parseFloat(quantity)

            if (!Number.isNaN(floatQuantity)) {
              newItem.quantity = Number(decimal(floatQuantity, { maximumFractionDigits: QUANTITY_DECIMAL_PLACES }))
              quantitySet = true
            }
          }
          else if (field.Type.Text === 'PRICE') {
            newItem.total_price = Number(extractNumber(field.ValueDetection.Text))
          }

          if (newItem.quantity && newItem.total_price) {
            const unitPrice = newItem.total_price / newItem.quantity

            if (Number.isFinite(unitPrice)) {
              newItem.unit_price = Number(decimal(unitPrice))
            }
          }
        })

        if (newItem.description) {
          if (scanItemsSku && productCode && !newItem.description.includes(productCode)) {
            newItem.description = `${productCode} | ${newItem.description}`
          }
          items.push(newItem)
        }
      })
    })

    doc.SummaryFields?.forEach((field: any) => {
      if (field.Type?.Text === 'DISCOUNT' && field.LabelDetection?.Text && field.ValueDetection?.Text) {
        const newItem = defaultItem(defaultCostCode ?? '', defaultUnit)

        newItem.description = removeLastDash(field.LabelDetection.Text)

        const discount = Number(extractNumber(field.ValueDetection.Text))

        if (!Number.isNaN(discount) && discount !== 0) {
          newItem.total_price = discount
          newItem.unit_price = discount
          items.push(newItem)
        }
      }
    })
  })

  return items
}
