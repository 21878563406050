import { APIError } from '@quotetome/materials-api'
import { sentry } from '@/plugins/sentry'
 
import { toast } from '@/plugins/toast'

export const errorHandler = {
  report: (error: any, show = true) => {
    if (!(error instanceof APIError)) {
      console.error(error)
      sentry.captureException(error)
      return {}
    }

    if (error.requestError) {
      console.error(error.requestError)
      sentry.captureException(error.requestError)
    }

    if (show) {
      toast.error(error.message)
    }

    return error.data || {}
  },
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      error: errorHandler,
    }
  }
})
